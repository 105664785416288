/*----------------------------------------------------------------------------*\
    FOOTER
    Bottom of page
\*----------------------------------------------------------------------------*/

/*  Variables
\*----------------------------------------------------------------------------*/



/*  Component
\*----------------------------------------------------------------------------*/
    
    .footer{
        background-color: color(brand);
        min-height: $overlay-height;


        .footer__content{
            position: relative;
            z-index: 10;
            .logo{
                width: 200px;
            }
            .slogan{
                color: color(blank);
                @include font-size($fontsize-l);
                font-family: $font-brand;
            }
        }

    }