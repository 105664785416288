/*----------------------------------------------------------------------------*\
    CARD
    Block with content, optional image
\*----------------------------------------------------------------------------*/

/*  Variables
\*----------------------------------------------------------------------------*/



/*  Component
\*----------------------------------------------------------------------------*/

    .card{}