/*----------------------------------------------------------------------------*\
    LIST
    ul / ol / / li + dl / dt / dd
\*----------------------------------------------------------------------------*/

/*  Variables
\*----------------------------------------------------------------------------*/



/*  Component
\*----------------------------------------------------------------------------*/

    /* Unordered list
    \*----------------------------------------------------------------------------*/
        ul{

            li{}
        }

    /* Ordered list
    \*----------------------------------------------------------------------------*/

        ol{

            li{}
        }

    /* (un)ordered list items
    \*----------------------------------------------------------------------------*/

        li{}

    /* Definition list
    \*----------------------------------------------------------------------------*/

        dl{}

            dt{}

            dd{}


    