/*----------------------------------------------------------------------------*\
    TABLE
    tabular data only, do not use for layout
\*----------------------------------------------------------------------------*/

/*  Variables
\*----------------------------------------------------------------------------*/

/*  Component
\*----------------------------------------------------------------------------*/

    table{}

        thead{}

        tbody{}

        tr{}

        th{
            @include rem(padding, $space-s);

            &:first-child{@include rem(padding, $space-s $space-s $space-s 0);}
            &:last-child{@include rem(padding, $space-s 0 $space-s $space-s);}
        }    

        td{
            @include rem(padding, $space-s);

            &:first-child{@include rem(padding, $space-s $space-s $space-s 0);}
            &:last-child{@include rem(padding, $space-s 0 $space-s $space-s);}
        }

    