/*----------------------------------------------------------------------------*\
    TOP LEVEL
    HTML, body, wrapper, site-width overrides
\*----------------------------------------------------------------------------*/

/*  Variables
\*----------------------------------------------------------------------------*/



/*  Component
\*----------------------------------------------------------------------------*/
html{
    color: color(base);
}
.button{
    background-color: color(brand);
    border-radius: $space-s;
    border: 0;
    padding: 0 $space-s;
    font-family: $font-brand;
    color: color(blank);

    &.button--sec{
        background-color: color(brand-sec);
        color: color(base);
    }
}
.form{
    label{
        display: block;
    }

    input[type="text"],
    input[type="email"]{
        margin-bottom: $space-s;
        width: 100%;
        border: 0;
        padding: $space-s/2 $space-s;
        color: color(base);
        border-radius: $space-s/2;
    }
}

