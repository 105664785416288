/*----------------------------------------------------------------------------*\
    MAIN MENU
    Main navigation, preferably first in the HTML
\*----------------------------------------------------------------------------*/

/*  Variables
\*----------------------------------------------------------------------------*/



/*  Component
\*----------------------------------------------------------------------------*/

    .nav--main{

    }