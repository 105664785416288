/*----------------------------------------------------------------------------*\
    Vars
\*----------------------------------------------------------------------------*/

/*  Variables
\*----------------------------------------------------------------------------*/




$site-width:                       1024px;

$base-font-size:                   16px;
$base-line-height:                 24px;
$line-height-ratio:                $base-line-height/$base-font-size;

$space:                            $base-line-height;
$space-xs:                         $space/4;
$space-s:                          $space/2;
$space-l:                          $space*2;
$space-xl:                         $space*4;

/* Modules */
$use-buttons:                      true;
$use-island:                       true;
$use-navigation:                   false;

/* layout */
$use-layout:                       true;

/* arrange */
$use-arrange:                      false;
$arrange-gutter:                   $space;
$breakpoint-has-collapsed-arrange: ();

/* grid */
$use-grid:                         true;
$enable-grid-rev:                  true;
$enable-grid-right:                false;
$enable-grid-center:               false;
$enable-grid-vertical:             false;
$enable-grid-narrow:               false;
$enable-grid-wide:                 false;
$enable-grid-flush:                true;

/* units */
$dimensions-use-units:             true;

/* units / divisions */
$dimensions-divisions:             12;

/* units / breakpoints */
$breakpoints: (
        'palm'      '(max-width: 480px)',
        'gt-palm'   '(min-width: 481px)',
        'lap'       '(min-width: 481px) and (max-width: 1024px)',
        'lt-desk'   '(max-width: 1024px)',
        'desk'      '(min-width: 1025px)'
);
$dimensions-breakpoint-has-units:  ('palm', 'lap', 'desk');

/* push */
$dimensions-use-push:              true;
$dimensions-breakpoint-has-push:   ('palm', 'lap', 'desk');

/* pull*/
$dimensions-use-pull:              false;
$dimensions-breakpoint-has-pull:   ();

/* helpers */
$breakpoint-has-helpers: ();

/* helpers */
$breakpoint-has-island: ();

/* arrange and layout */
$dimensions-breakpoint-has-fit:    ();
$dimensions-breakpoint-has-fill:   ();

/* fixed ratio */
$use-fixedratio:                   false;

/* icons */
$use-icons:                        false;

/* images */
$use-image:                        false;

/* Form elements */
$use-forms:                        false;


$header-height: $space*6;


/*----------------------------------------------------------------------------*\
    $TYPOGRAPHY
\*----------------------------------------------------------------------------*/

$fontsize-xl:       49px;
$fontsize-l:        34px;
$fontsize-m:        $base-font-size;
$fontsize-s:        16px;


$font-base:        'Roboto', sans-serif;
$font-brand:       'Roboto slab', sans-serif;


/*----------------------------------------------------------------------------*\
    $COLORS
\*----------------------------------------------------------------------------*/


$colors: (
    base:                           rgba(#111111, 0.8),
    blank:                          #FDFDFD,
    subtle:                         #F6F6F6,
    
    brand:                          #DF3988,
    brand-sec:                      #FDCA15,
    brand-ter:                      #29235C
);

//TODO: color tint mixin? https://medium.com/@erikdkennedy/color-in-ui-design-a-practical-framework-e18cacd97f9e

/*----------------------------------------------------------------------------*\
    $ANIMATIONS
\*----------------------------------------------------------------------------*/

$ani:                               cubic-bezier(0.4, 0.0, 0.2, 1.0);
$dur:                               .300s;
$transition:                        $dur $ani;


$overlay-height: $space*2;