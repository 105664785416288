/*----------------------------------------------------------------------------*\
    HEADER
    Top of page
\*----------------------------------------------------------------------------*/

/*  Variables
\*----------------------------------------------------------------------------*/



/*  Component
\*----------------------------------------------------------------------------*/

    .header{
        height: $header-height;
        position: relative;
        z-index: 12;

        .overlay{
            height: 100%;
            width: 100%;
            position: absolute;
            left: 0;
            right: 0;
            top:0;
            z-index: 1;
            fill: color(blank);
        }
        .header-inner{
            position: relative;
            z-index: 11;
            padding-top: $space;
        }
        .logo{
            max-width: 300px;
        }
    }