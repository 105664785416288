/*----------------------------------------------------------------------------*\
    HELPERS
    various small helper classes
\*----------------------------------------------------------------------------*/

/*  Variables
\*----------------------------------------------------------------------------*/



/*  Component
\*----------------------------------------------------------------------------*/

    