.hero{
    background-size: cover;
    background-position: top;
    min-height: $space*35;
    position: relative;
    padding-top: $header-height;
    margin-top: $header-height*-1;

    .overlay{
        fill: color(brand);
        height: $overlay-height*2;
        bottom: $space*2;
    }
    &:after{
        content: '';
        bottom: 0;
        left: 0;
        right: 0;
        height: $space*2;
        display: block;
        position: absolute;
        background-color: color(brand);
    }
}

.bubble{
    padding: $space-l $space-l;
    background-color: color(brand-sec);
    position: absolute;
    bottom: $space;
    max-width: calc(#{$site-width} - #{$space}*6);
    left: $space;
    right: $space;
    margin-left: auto;
    margin-right: auto;
    z-index: 10;
    border-radius: $space;

    &:before{
        content: "";
        position: absolute;
        top: 10px;
        left: 10px;
        right: -8px;
        bottom: -3px;
        background: rgba(0,0,0,0.2);
        z-index: -1;
        border-radius: $space;

        transform: rotate(1deg);
    }

    &:after{
        content: "";
        position: absolute;
        top: 0;
        left: 0px;
        right: 0px;
        bottom: 0;
        background-color: color(brand-sec);
        z-index: -1;
        border-radius: $space;
    }

    .bubble__title{
        color: color(brand-ter);
        margin-bottom: $space;
    }

}

@include media-query(palm){
    .bubble{
        //position: relative;
        margin-top: $space*2;
        width: 90%;
        left: 0;
        right: 0;
        padding: $space;
    }
}