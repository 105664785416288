/*----------------------------------------------------------------------------*\
    Switch
    Based on switch.js
\*----------------------------------------------------------------------------*/

/*  Variables
\*----------------------------------------------------------------------------*/



/*  Component
\*----------------------------------------------------------------------------*/

    *[data-role=switchee][data-state=passive]{
        @extend .visuallyhidden;
    }