/*----------------------------------------------------------------------------*\
    TYPOGRAPHY
    text etc
\*----------------------------------------------------------------------------*/

/*  Variables
\*----------------------------------------------------------------------------*/



/*  Component
\*----------------------------------------------------------------------------*/

    h1, h2, h3, h4, h5, h6{
        font-family: $font-brand;
        font-weight: 700;
        line-height: 1em;
    }

    h1{
        @include font-size($fontsize-xl);
        line-height: 1em;
    }

    h2{
        @include font-size($fontsize-l);
        line-height: 1em;
    }

    h3, h4, h5, h6{
        @include font-size($fontsize-m);
        line-height: 1em;
    }

    .text-small{
        @include font-size($fontsize-s);
    }