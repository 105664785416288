/*----------------------------------------------------------------------------*\
    quote
\*----------------------------------------------------------------------------*/

/*----------------------------------------------------------------------------*\
    
\*----------------------------------------------------------------------------*/

.quote{
    display: flex;
    width: 100%;

    .quote__content{
        flex: 1 0 50%;
        padding: $overlay-height*1.2 0;
        position: relative;
    }
    .quote__image{
        flex: 1 0 50%;
        position: relative;
        margin-top: $overlay-height*-1;
        margin-bottom: $overlay-height*-1;
        background-position: top;
        background-size: cover;
    }
    .quote__name{
        display: block;
        color: color(blank);
        margin-bottom: $space-s;
    }
    .quote__title{
        margin-bottom: $space;
        text-indent: -12px;
        color: color(brand-ter);
    }

    .content{
        float: right;
    }

    &.quote--even{
        .quote__content{
            order: 1;
            .content{
                float: none;
            }
        }
        .quote__title{
            color: color(brand-sec);
        }
        .quote__image{
            order: 0;
        }
    }
}

/* Media Queries
   The default media queries shipped with Blocss:
   Uncomment the ones you need
\*------------------------------------------------------------------------*/

/**
 * Desk-wide state
 */
//@include media-query(gt-desk){}

/**
 * Desk state
 */
//@include media-query(desk){}

/**
 * Portable state
 */
//@include media-query(lt-desk){}

/**
 * Lap state
 */
//@include media-query(lap){}

/**
 * Lapup state
 */
//@include media-query(gt-palm){}

/**
 * Palm state
 */
@include media-query(palm){
    .quote{
        flex-direction: column;
        .quote__content{
            order: 1 !important;
        }
        .quote__image{
            min-height: $space*14;
            order: 0 !important;
        }
    }

}

