/*----------------------------------------------------------------------------*\
    LAYER
    Page part
\*----------------------------------------------------------------------------*/

/*  Variables
\*----------------------------------------------------------------------------*/



/*  Component
\*----------------------------------------------------------------------------*/

    .layer{
        padding: 0;
    }

    .layer--odd{
        background-color: color(brand-sec);
        .overlay{
            fill: color(brand);
        }

        .content__title{
            color: color(brand-ter);
        }
    }

    .layer--even{
        background-color: color(brand);

        .overlay{
            fill: color(brand-sec);
        }

        .content__title{
            color: color(brand-sec);
        }
    }

    .content{
        max-width: $site-width/2.5;
        padding: 0 $space*2;
        position: relative;
        z-index: 10;

        &.content--right{
            float: right;
        }
    }
    .image__container{
        position: relative;
        //background: center center cover;
        height: 100%;
    }
    .overlay{
        height: $overlay-height;
        width: 100%;
        position: absolute;
        left: 0;
        right: 0;
    }

    .overlay--upper{
        top: 0;
    }
    .overlay--lower{
        bottom: 0;
    }

@include media-query(palm){
    .layer--odd{
        background-color: color(brand-sec);
        .overlay--lower{
            fill: color(brand-sec);
        }

        .content__title{
            color: color(brand-ter);
        }
    }

    .layer--even{
        background-color: color(brand);

        .overlay--lower{
            fill: color(brand);
        }

        .content__title{
            color: color(brand-sec);
        }
    }

}